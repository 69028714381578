import React from 'react'
import RichText from "../richText"
import StyledLinkButton from "../common/StyledLinkButton"
import StyledCard from '../common/StyledCard'
import { Card } from "react-bootstrap"
import { Link } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export default function ({ title, content, buttonLabel, buttonDestination, featuredImage }) {

    const goToPageLink = (
        <StyledLinkButton to={buttonDestination} className="btn-sm btn btn-outline-danger">
            {buttonLabel}
        </StyledLinkButton>
    )
    
    return (
        <StyledCard>
                {featuredImage ? // check if image was delivered and render it conditionally
                <Link to={buttonDestination}>
                    <Card.Img src={featuredImage.url} alt={featuredImage.alt} className="featured-image-wrapper rounded" />
                </Link>
                    : null}
            <Card.Body>
                <Card.Title className="mt-3">
                    <PrismicRichText
                        field={title}
                        components={{
                            heading2: ({ children }) => <h2 className='card-title'>{children}</h2>
                        }}
                    />
                </Card.Title>
                <RichText render={content} />
            </Card.Body>
            <Card.Footer>
                {buttonLabel ? goToPageLink : null}
            </Card.Footer>
        </StyledCard>
    )
}
